/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */
'use strict';

import PropTypes from 'prop-types';
import '../../stylesheets/grid-flex/grid-flex.css';

const Grid = ({ children }) => children;

Grid.propTypes = {
  children: PropTypes.node
};

export default Grid;
